<template>
  <div class="text-center">
      <CCol>
        <img class="m-3" width="200px" src="../../../public/img/logo-new-v.png" alt="Main_logo">
        <div class="h2 mb-4 pb-2">출발사진 업로드</div>
          <div class="row">
            <div class="col-6">
              <h6>차종 :</h6>
            </div>
            <div class="col-6">
              <h6>{{ carKind }}</h6>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <h6>차량 번호 :</h6>
            </div>
            <div class="col-6">
              <h6>{{ plateNo }}</h6>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <h6>출발지 주소 :</h6>
            </div>
            <div class="col-6">
              <h6>{{ stAddr }}</h6>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <h6>도착지 주소 :</h6>
            </div>
            <div class="col-6" >
              <h6>{{ edAddr }}</h6>
            </div>
          </div>
      </CCol>
    
    <ImageUpload @uploadImage="updateImageUrl" @uploadSuccess="showSuccessModal" :info="info" />
    <SuccessModal ref="successModal" />
  </div>
</template>

<script>
import ImageUpload from './ImageUpload.vue';
import SuccessModal from './SuccessModal.vue';
import axios from 'axios';

export default {
  components: {
    ImageUpload,
    SuccessModal,
  },
  data() {
    return {
      selectedImageUrl: null, // 이미지 URL 초기화
      token: this.$route.params.token, // :token 값을 가져와서 token 데이터에 저장
      carKind: '',
      plateNo: '',
      stAddr: '',
      edAddr: '',
      info: this.info,
    };
  },
  mounted(){
    console.log('parameter : ', this.$route.params);
    this.sendTokenToServer(); // 마운트 시 토큰을 서버로 보내는 함수 호출
  },
  methods: {
    updateImageUrl(imageUrl) {
      this.selectedImageUrl = imageUrl;
    },
    showSuccessModal() {
      this.$refs.successModal.showModal(); // SuccessModal 컴포넌트의 showModal 메서드 호출
    },
    sendTokenToServer() {
      // 토큰 값을 가져온 Vue 컴포넌트에서 사용
      const token = this.token;

      // 서버 엔드포인트 URL (서버로 토큰 값을 전달)
      const apiUrl = '/api/uploadToken';

      // Axios를 사용하여 서버로 POST 요청을 보냄
      axios.post(apiUrl, { token })
        .then(async (response) => {
          // 서버 응답을 처리
          const responseData = response.data;
          console.log('서버 응답:', responseData);
          if (responseData.message === '토큰이 유효합니다.') {
        // 토큰이 유효한 경우
        const dataInToken = responseData;
        console.log('토큰 안의 데이터:', dataInToken);

        const info = dataInToken.data; // 데이터를 info에 저장
        console.log('토큰 안의 데이터2:', info);

      this.info = dataInToken.data
      this.carKind  = info.carKind
      this.plateNo  = info.plateNo
      this.carOwner = info.carOwner 
      this.stAddr   = info.stAddr
      this.edAddr   = info.edAddr 
      this.reqName  = info.reqName
      
      } else {
        // 토큰이 유효하지 않은 경우
        console.error('토큰이 유효하지 않습니다.');
      }
    })
    .catch((error) => {
      // 요청 실패 시 오류 처리
      console.error('HTTP 요청 실패:', error);
        });
    },
  },
};
</script>
<style scoped>
</style>