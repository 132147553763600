import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import {cloneVar, apiAuth, apiAuthMe, apiLogout, refreshToken, connectWebSocket, beepSound} from './common/utils'


Vue.use(Vuex)

const _user = {
  _id        : null,
  id         : null,
  loginUserId: null,
  nick       : null,
  name       : null,
  position   : null,
  role       : null,
  email      : null,
  hpNo       : null,
  phoneNo    : null,
  accessToken :null,
  tokenExpireMin: 0,
  centerCode : null,
  userClass : 0,
  center : {
    centerCode: null,
    centerName: null,
    addr: null,
    addrDtl: null,
    addrXy: null,
    hpNo: null
  },
  addr: null,
  addrDtl: null,
  regionAddr : null
};

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: true,
  darkMode: true,
  asideShow: false,
  // ------------ below --- added for application ------------
  messageBarShow: false,
  messageBarMinimize: false,
  isAuth: false,
  expireSecIn: 0,
  soundOn: false,
  isDrvOn: false,
  isMsgOn: false,
  voice: null, // 목소리
  drvMsgs: [],
  user: {
    _id        : null,
    id         : null,
    loginUserId: null,
    nick       : null,
    name       : null,
    position   : null,
    role       : null,
    email      : null,
    hpNo       : null,
    accessToken :null,
    tokenExpireMin: 0,
    centerCode  : null,
    userClass : 0,
    center : {
      centerCode: null,
      centerName: null,
      addr: null,
      addrDtl: null,
      addrXy: null,
      hpNo: null
    },
    addr: null,
    addrDtl: null,
    regionAddr : null
  },

  socket: null,
  codeMaps: {},
  codeOpts: {},


  // ------------ above --- added for application ------------

}

const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    console.log( 'store.set() --- register global variable ---', variable);
    state[variable] = value
  },
  async toggle (state, variable) {
    state[variable] = !state[variable];
    await beepSound('success')
  },
  // ------------ below --- added for application ------------
  get (state, key) {
    return state[key];
  },

  LOGIN (state, user) {
    console.log( "**********************  store-mutation-LOGIN  **********************");
    state.user = user;
    localStorage.user = JSON.stringify(user);
    state.expireSecIn = (user.tokenExpireMin * 60) - 5;
    axios.defaults.headers.common['x-access-token'] = user.accessToken;
    state.isAuth = true;

    console.log( "**********************  store-mutation-LOGIN --- state.user ---", state.user);

    setTimeout( async ()=>{
      await connectWebSocket(user.centerCode, user.accessToken);
    }, 1000 );

    // console.log( "LOGIN().accessToken: " + user.accessToken );
  },

  setToken (state, auth) {
    console.log("========= store-mutation-setToken ========= ");
    state.user.accessToken = auth.accessToken;
    state.user.tokenExpireMin = auth.tokenExpireMin;
    state.expireSecIn = (auth.tokenExpireMin * 60) - 5;
    localStorage.user = JSON.stringify(state.user);


  },

  async LOGOUT (state) {
    console.log("========= store-mutation-LOGOUT ========= ");
    try{
      state.user.accessToken = null;
      state.isAuth = false;
      state.user = cloneVar( _user );
      state.socket = null;
      delete localStorage.user;

    }catch(err){
      console.log( "========= store-mutation-LOGOUT ----->logout error--->", err );
    }
  }

  // ------------ above --- added for application ------------

}

// CS: Center Supervisor CM: Center Dealer , CT: Center Teller
const classMap = { CS: 1, CD: 2, CT:3 };


const actions = {
  async LOGIN ({commit}, {userId, userPwd }) {
    // console.log( "*** ------------------- store----action-----LOGIN -------------------- ***");

    const param = {
      loginId: userId,
      loginPwd: userPwd,
    };

    // const encodedParam = qs.stringify( param );
    // const serverUrl = $baseURL;
    try{
      const rs = await apiAuth( param );
      // console.log("############ STORE_ACTION___LOGIN  ===============>  result: ", rs );

      if( rs.code===200 ) {
        if (!rs.result.accessToken)
          throw new Error("no Access Token");

        axios.defaults.headers.common['x-access-token'] = rs.result.accessToken;

        const {user} = rs.result;
        const {loginUser} = rs.result;

        if (user) new Error("cannot get user info");
        const {accessToken} = rs.result;

        const commitUser = {
          _id: user._id,
          id: user.userId,
          loginUserId: loginUser._id,
          nickName: loginUser.nickName,
          name: loginUser.name,
          position: loginUser.position,
          role: user.role,
          email: loginUser.email,
          hpNo: loginUser.hpNo,
          phoneNo: loginUser.phoneNo,
          centerCode: loginUser.centerCode,
          wgCode: loginUser.wgCode,
          tokenExpireMin: user.tokenExpireMin,
          accessToken: accessToken,
          userClass: classMap[user.role],
          isAuth: true,
          center: loginUser.center,
          customer: loginUser.customer,
          addr: loginUser.addr,
          addrDtl: loginUser.addrDtl,
          addrXy: loginUser.addrXy
        }


        console.log('################ STORE_ACTION___LOGIN ----commitUser---------->', commitUser);

        commit('LOGIN', commitUser);
      }

      return rs;

    }catch(err){
      console.log("################ STORE___ACTION___LOGIN error: ", err );

      // alert("SYSTEM NOT Ready...try again");
      // commit('LOGOUT');
      throw err;
    }

  },

  async LOGOUT ({commit}) {
    console.log('################ STORE_ACTION___LOGOUT ################');
    try{
      if( axios.defaults.headers.common['x-access-token'] ){
        const rs = await apiLogout();
        console.log( "################ STORE_ACTION___LOGOUT ----->logout result--->", rs );
      }

      if(state.socket){
        console.log( "################ STORE_ACTION___LOGOUT-----> socket: ", state.socket );
        state.socket.removeAllListeners();
        state.socket.disconnect();
      }
      await commit('LOGOUT');

    }catch(err){
      console.log("################ STORE_ACTION___LOGOUT Error:", err)
    }finally{
      axios.defaults.headers.common['x-access-token'] = undefined;
    }

  },

  async refreshToken({commit}) {
    // console.log('################ STORE_ACTION___refreshToken ################');
    console.log('################ STORE_ACTION___refreshToken ################');
    const rs = await refreshToken();
    if(rs.code===200){
      axios.defaults.headers.common['x-access-token'] = rs.result.accessToken;
      await commit('setToken', rs.result); // mutation call
      // await commit('LOGIN', rs.result); // mutation call
      return true;
    }else{
      return false;
    }
  },

  async setLoginState({commit}, user){
    try{
      axios.defaults.headers.common['x-access-token'] = user.accessToken;

      const rs = await apiAuthMe();
      console.log( "@@@@@@@@@@ setLoginState() --- apiAuthMe()---------------> result --------->", rs );
      if( rs.code===200 ) {
        if (!rs.result.accessToken)
          throw new Error("no Access Token");

        axios.defaults.headers.common['x-access-token'] = rs.result.accessToken;
        const {loginUser} = rs.result;
        const {user} = rs.result;

        if (user) new Error("cannot get user info");


        const commitUser = {
          _id: user._id,
          id: user.userId,
          loginUserId: loginUser._id,
          nickName: loginUser.nickName,
          name: loginUser.name,
          position: loginUser.position,
          role: user.role,
          email: loginUser.email,
          hpNo: loginUser.hpNo,
          phoneNo: loginUser.phoneNo,
          centerCode: loginUser.centerCode,
          wgCode: loginUser.wgCode,
          tokenExpireMin: rs.result.tokenExpireMin,
          accessToken: rs.result.accessToken,
          userClass: classMap[user.role],
          isAuth: true,
          center: loginUser.center,
          customer: loginUser.customer
        };

        await connectWebSocket(state.user.centerCode, state.user.accessToken);

        console.log('@@@@@@@@@@ setLoginState() ----> commit user---------->', commitUser);
        await commit('LOGIN', commitUser);
        return true;

      } else {
        commit('LOGOUT');
        return false;
      }
    }catch(err){
      console.log( "@@@@@@@@@@ setLoginState Error ------------> ", err.message );
      commit('LOGOUT');
      throw err;
    }
  }
};


export default new Vuex.Store({
  state,
  mutations,
  actions
})
