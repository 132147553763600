<template>
    <div class="modal" v-if="isModalVisible">
      <div class="modal-content">
        <h2>성공</h2>
        <p>이미지 업로드에 성공했습니다!</p>
        <button class="close_button" @click="closeModal">닫기</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        isModalVisible: false,
      };
    },
    methods: {
      showModal() {
        this.isModalVisible = true;
      },
      closeModal() {
        this.isModalVisible = false;
      },
    },
  };
  </script>
  
  <style scoped>
  /* 팝업 스타일링 */
  .modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
  }

  .close_button{
    display: inline-block;
    background-color: #321FDB;
    color: #ffffff;
    padding: 10px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    margin: 10px;
  }
  </style>