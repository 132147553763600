import Vue from 'vue'
import Router from 'vue-router'
import {apiAuthAccess} from "../common/utils";
import store from '@/store'


// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views
const Dashboard = () => import('@/views/drive/Dashboard.vue')

const Login = () => import('@/views/Login.vue');
const Credit = () => import('@/views/Credit');

const Request = () => import('@/views/drive/Request.vue');


const departure = () => import('@/views/imageUploadDep/ImageMaster.vue');
const arrival = () => import('@/views/imageUploadArr/ImageMaster.vue');
const via = () => import('@/views/imageUploadVia/ImageMaster.vue');


const Toaster = () => import('@/views/notifications/Toaster')
// const Machine = () => import('@/views/setting/Machine');
// const Tank = () => import('@/views/setting/Tank');
// const Site = () => import('@/views/setting/Site');
// const Oil = () => import('@/views/setting/Oil');
// const TankTable = () => import('@/views/setting/TankTable');
// const Alarms = () => import('@/views/setting/Alarms');
// const Services = () => import('@/views/setting/Services');
//
//
// const TankData = () => import('@/views/inventory/TankData');
// const OilData = () => import('@/views/inventory/OilData');
// const TankInOut = () => import('@/views/inventory/TankInOut');
// const Packet = () => import('@/views/monitor/Packet');
// const MonitorMain = () => import('@/views/monitor/MonitorMain');
// const TankMonitor = () => import('@/views/monitor/TankMonitor');
// const Events = () => import('@/views/monitor/Events');

// const UserMng = () => import('../views/drive/users/UserMng');

const UserInfo = () => import('../views/drive/users/UserInfo');
const UserLog = () => import('../views/drive/users/UserLog');
// const AccessLog = () => import('../views/users/AccessLog');
// const AccessMap = () => import('../views/users/AccessMap');
const PasswordReset = () => import('../views/drive/users/PasswordReset');

const requireAuth = () => async (to, from, next) => {
  // console.log( "requireAuth()-------------> isAuth ----->", store.state.isAuth);
  // console.log( 'requireAuth()-------------> from-path -->', from.path );
  console.log( 'requireAuth()-------------> to-path ---->', to.path );
  try {
    if(!store.state.isAuth){ return next('/login') }
    const rs = await apiAuthAccess({path: to.path, name: to.name});
    console.log('requireAuth---apiAuthAccess return --->', rs);
    switch(rs.code){
      case 200: return next();
      case 8423: return alert(`[${rs.code}] 접근 권한이 없습니다.`);
      case 8424: return alert(`[${rs.code}] '${to.name}' 접근이 거부 되었습니다.`);
      case 7406:
        alert("[중복 로그인] 로그아웃 되었습니다.");
        return next('/login');
      default:
        alert( `[${rs.code}] 로그인이 필요합니다.`);
        return next('/login');
    }
    /*
    if (rs.code === 200 && store.state.isAuth) {
      return next();
    }else if ( rs.code > 400 ){
      return alert('접근 할 수 없습니다.');
    }else{
      // alert("[WARNING] 비정상 접근입니다.");
      // store.state.isAuth = false;
      next('/login');
    }*/
  }catch(err){
    console.error(err);
  }


};


Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          beforeEnter: requireAuth(),
          component: Dashboard
        },
        {
          path: 'toaster',
          name: 'Toaster',
          component: Toaster
        },
        {
          path: 'request',
          name: '탁송 예약',
          beforeEnter: requireAuth(),
          component: Request,
        },
        {
          path: 'setting',
          redirect: '/setting/user-info',
          name: '사용자정보',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'user-info',
              name: '내정보',
              beforeEnter: requireAuth(),
              component: UserInfo
            }
          ]
        },
        {
          path: 'users',
          redirect: '/users/user-mng',
          name: '사용자',
          component: {
            render (c) { return c('router-view') }
          },
          children: [
            {
              path: 'user-info',
              name: '내정보',
              beforeEnter: requireAuth(),
              component: UserInfo
            },
            {
              path: 'user-mng',
              name: '사용자관리',
              beforeEnter: requireAuth(),
              component: UserInfo
            },
            {
              path: 'user-log',
              name: '로그인 기록',
              beforeEnter: requireAuth(),
              component: UserLog
            },
            {
              path: 'access-log',
              name: '접근 기록',
              beforeEnter: requireAuth(),
              // component: AccessLog
            },
            {
              path: 'access-map',
              name: '접근 설정',
              beforeEnter: requireAuth(),
              // component: AccessMap
            }
          ]
        }
      ]
    },
    { path: '/login', name: 'Login', props: true, component: Login },
    { path: '/upload', name: 'Upload', props: true, component: Login },
    { path: '/credit', name: 'Credit', props: false, component: Credit },
    { path: '/pwd-reset', name: 'PasswordReset', props: true, component: PasswordReset,},
    { path: '/upload/departure/:token', name: 'Departure', props: true, component: departure},
    { path: '/upload/arrival/:token', name: 'Arrival', props: true, component: arrival},
    { path: '/upload/via/:token', name: 'Via', props: true, component: via},
  ]
})
