<template>
  <div class="m-4 p-2">
    <div>
      <p>이미지를 선택하여 주세요.</p>
      <!-- 이미지 선택 버튼 -->
      <label for="fileInput" class="select-button">
        <input id="fileInput" type="file" @change="uploadImage" accept="image/*" multiple />
        이미지 선택
      </label>
    </div>
    <div v-if="selectedImages.length > 0">
      <!-- 업로드 선택 버튼 -->
      <p>선택한 이미지 입니다.</p>
      <div v-for="(image, index) in previewUrls" :key="index">
        <img :src="image" alt="Preview Image" class="preview-image" />
      </div>
      <button class="upload-button" type="button" @click="upload" :disabled="uploading">
        <span v-if="uploading">업로드 중...</span>
        <span v-else>업로드</span>
      </button>
    </div>
    <p v-if="errorMessage" class="error-message">{{ errorMessage }}</p>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'Departure',
  props: {
    info: Object
  },
  data() {
    return {
      selectedImages: [], // 초기값을 빈 배열로 설정
      previewUrls: [], // 이미지 미리보기 URL 배열
      errorMessage: '', // 업로드 실패 시 에러 메시지
      uploading: false// 업로드 중 상태 추가
    };
  },
  methods: {
    uploadImage(event) {
      const files = event.target.files;
      if (!files.length) {
        console.error('파일이 선택되지 않았습니다.');
        return;
      }

      this.selectedImages = Array.from(files); // 선택한 파일 배열로 설정
      this.previewUrls = this.selectedImages.map(file => URL.createObjectURL(file)); // 미리보기 URL 배열 설정
    },
    async upload() {
      if (this.selectedImages.length === 0 || this.uploading) {
        console.error('이미지를 선택하세요.');
        return;
      }
      this.uploading = true; // 업로드 중 상태 설정

      const formData = new FormData();
      this.selectedImages.forEach(image => {
        formData.append('images', image); // 폼 데이터에 이미지 추가
      });

      try {
        await axios.post('/api/depUpload/'+this.info._id, formData); // 백엔드 URL로 수정

        // 이미지 업로드 후 초기화
        this.selectedImages = [];
        this.previewUrls = []; // 미리보기 초기화
        this.errorMessage = '';

        // 업로드 성공 시 이벤트 발생
        this.$emit('uploadSuccess');
      } catch (error) {
        console.error('Image upload failed:', error);
        this.errorMessage = '업로드를 실패하였습니다.';
      } finally {
        this.uploading = false; // 업로드 후 로딩 해제
      }
    },
  },
};
</script>

<style scoped>
/* 스타일링 */
.select-button {
  display: inline-block;
  background-color: #321FDB;
  color: #ffffff;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
}

.select-button input[type="file"] {
  display: none;
}

.upload-button {
  display: inline-block;
  background-color: #321FDB;
  color: #ffffff;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  margin: 15px;
}

.preview-image {
  max-width: 100%; /* 이미지 최대 너비 설정 */
  max-height: 300px; /* 이미지 최대 높이 설정 */
}
</style>
