import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router/index'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import CoreuiVuePro from '@coreui/vue-pro'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import i18n from './i18n.js'

import imageUploadDep from './views/imageUploadDep/ImageMaster.vue';
import imageUploadArr from './views/imageUploadArr/ImageMaster.vue';
import imageUploadVia from './views/imageUploadVia/ImageMaster.vue';


Vue.use(BootstrapVue) // add BootstrapVue
Vue.use(IconsPlugin) // add IconsPlugin
Vue.use(CoreuiVuePro)


Vue.prototype.$log = console.log.bind(console)

new Vue({
  el: '#app',
  router,
  store,
  //CIcon component documentation: https://coreui.io/vue/docs/components/icon
  icons,
  imageUploadDep,
  imageUploadArr,
  imageUploadVia,
  i18n,
  template: '<App/>',
  components: {
    App
  }
})
