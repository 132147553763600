<template>
  <div class="text-center">
    <CCol>
      <img class="m-3" width="200px" src="../../../public/img/logo-new-v.png" alt="Main_logo">
      <div class="h2 mb-4 pb-2">도착사진 업로드</div>
      <div class="row">
        <div class="col-6">
          <h6>차종 :</h6>
        </div>
        <div class="col-6">
          <h6>{{ carKind }}</h6>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <h6>차량 번호 :</h6>
        </div>
        <div class="col-6">
          <h6>{{ plateNo }}</h6>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <h6>출발지 주소 :</h6>
        </div>
        <div class="col-6">
          <h6>{{ stAddr }}</h6>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <h6>도착지 주소 :</h6>
        </div>
        <div class="col-6">
          <h6>{{ edAddr }}</h6>
        </div>
      </div>
    </CCol>
    <ImageUpload @uploadImage="updateImageUrl" @uploadSuccess="showSuccessModal" :info="info" />
    <SuccessModal ref="successModal" />
  </div>
</template>

<script>
import ImageUpload from './ImageUpload.vue';
import SuccessModal from './SuccessModal.vue';
import axios from 'axios';

export default {
  name: 'App',
  components: {
    ImageUpload,
    SuccessModal,
  },
  data() {
    return {
      selectedImageUrl: null,
      token: this.$route.params.token,
      carKind: '토큰에 데이터가 없습니다.',
      plateNo: '',
      carOwner: '',
      stAddr: '',
      edAddr: '',
      info: this.info,
    };
  },
  mounted() {
    console.log('parameter : ', this.$route.params);
    this.sendTokenToServer();
  },
  methods: {
    updateImageUrl(imageUrl) {
      this.selectedImageUrl = imageUrl;
    },
    showSuccessModal() {
      this.$refs.successModal.showModal();
    },
    sendTokenToServer() {
      const token = this.token;
      const apiUrl = '/api/uploadToken';
      axios.post(apiUrl, { token })
        .then(async (response) => {
          const responseData = response.data;
          console.log('서버 응답:', responseData);
          if (responseData.message === '토큰이 유효합니다.') {
            const dataInToken = responseData;
            console.log('토큰 안의 데이터:', dataInToken);
            const info = dataInToken.data;
            console.log('토큰 안의 데이터2:', info);

            this.info = dataInToken.data
            this.carKind = info.carKind
            this.plateNo = info.plateNo
            this.carOwner = info.carOwner
            this.stAddr = info.stAddr
            this.edAddr = info.edAddr

          } else {
            console.error('토큰이 유효하지 않습니다.');
          }
        })
        .catch((error) => {
          console.error('HTTP 요청 실패:', error);
        });
    },
  },
};
</script>

